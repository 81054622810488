import styled from 'styled-components'
import { colors } from 'config'

const Button = styled.button`
  appearance: none;
  border: none;
  background: ${colors.primary};
  color: white;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
`

export default Button