import React from 'react'
import {
  Outer,
  Image,
  Name,
  Title,
  Content,
  Footer,
  LinkedinIcon
} from './styles'


const TeamCard = ({ name, title, imageUrl, linkedinUrl, children }) => (
    <Outer>
      <Image source={imageUrl} />
      <Content>
        <Name>{ name }</Name>
        <Title>{ title }</Title>
        { children }
      </Content>
      <Footer>
        <a href={linkedinUrl}>
          <LinkedinIcon size={32} />
        </a>
      </Footer>
    </Outer>
)

export default TeamCard