import styled from 'styled-components'
import { colors } from 'config'
import { lighten } from 'polished'

export const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
`

export const Input = styled.input`
  appearance: none;
  font-size: 14px;
  border: 1px solid ${colors.text};
  padding: 10px 15px;
  width: 100%;
`

export const Error = styled.div`
  width: 100%;
  font-size: 12px;
  padding: 5px;
  color: red;
  border: 1px solid red;
  border-top: none;
  background: ${lighten(0.4, '#ff0000')};
`