import styled from 'styled-components'
import { colors, breakpoints } from 'config'
import { LinkedinSquare } from 'styled-icons/boxicons-logos/LinkedinSquare'

export const Outer = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: white;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  flex: 0 0 100%;
  overflow: hidden;
  transition: 0.3s all;
  margin-bottom: 24px;
  
  @media (min-width: ${breakpoints.largeMobileUp}) {
    flex: 0 0 calc(50% - 24px);
  }
  
  @media (min-width: ${breakpoints.tabletUp}) {
    flex: 0 0 calc(33.3333% - 24px);
  }
  
  &:hover {
    transition: 0.3s all;
    transform: translateY(-7px);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.1);
  }
`

export const Content = styled.div`
  flex: 1;
  padding: 24px;
  
  p {
    line-height: 1.5;
    font-size: 14px;
  }
`

export const Image = styled.figure`
  display: block;
  background-image: url(${ props => props.source });
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 300px;
  flex: 0 0 auto;
  margin: 0;
`

export const Name = styled.h3`
  font-size: 24px;
  font-weight: lighter;
  color: ${colors.primary};
  margin: 0;
`

export const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
`

export const Footer = styled.div`
  width: 100%;
  padding: 10px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.07)
`

export const LinkedinIcon = styled(LinkedinSquare)`
  color: rgba(0, 0, 0, 0.1);
  transition: color 0.3s;
  
  &:hover {
    color: #0077B5;
    transition: color 0.3s;
  }
`