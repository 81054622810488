import { createStore, applyMiddleware, combineReducers } from 'redux'
import logger from 'redux-logger'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import * as reducers from './modules'

const sagaMiddleware = createSagaMiddleware()

const middleware = applyMiddleware(sagaMiddleware, logger)

export default createStore(combineReducers(reducers), middleware)

sagaMiddleware.run(rootSaga)