import React, { Fragment } from 'react'
import { breakpoints } from 'config'
import { Navigation, Footer, NavigationMobile } from 'components'
import { Page } from './styles'
import MediaQuery from 'react-responsive'


const PageComp = ({ children }) => (
    <Fragment>
      <MediaQuery minWidth={breakpoints.tabletUp}>
        { matches => matches ? <Navigation /> : <NavigationMobile /> }
      </MediaQuery>
      <Page>
        { children }
        <Footer />
      </Page>
    </Fragment>
    
)

export default PageComp
