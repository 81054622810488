import { takeEvery, call, all, put } from 'redux-saga/effects'
import * as filesModule from 'modules/files'
import { filesActions } from 'modules/files'
import { fetchFiles as fetchFilesApi, fetchDescription, fetchGeoJson } from 'api/files'
import { indexBy, prop, evolve, map, compose, values, filter, complement, reverse } from 'ramda'

const matchImageFiles = file => file.match(/\.[sar|raw]+$/i)

const composeData = compose(
    indexBy(prop('name')),
    map(evolve({
      data: compose(
          filter(complement(matchImageFiles)),
          map(prop('name'))
      ),
      images: compose(
          filter(matchImageFiles),
          map(prop('name'))
      )
    })),
    map( dataSet => ({
      ...dataSet,
      images: [...dataSet.data]
    })),
    values
)

function* fetchFiles({ payload }) {
  const files  = yield call(fetchFilesApi, payload)
  
  const filesById = composeData(files.data.values)
  
  yield put(filesActions.fetchSuccess(filesById))
  yield put(filesActions.setGroup(files.data.values[0].name))
}


function* fetchDetails({ payload: { userId, groupName } }) {
  const [description, geoJson]  = yield all([
      call(fetchDescription, userId, groupName),
      call(fetchGeoJson, userId, groupName)
  ])
  
  yield put(filesActions.fetchDetailsSuccess({
    description: description.data,
    geoJson: map(reverse)(geoJson.data.coordinates)
  }))
}



export default function* watchFilesSaga() {
  yield takeEvery(filesModule.FETCH, fetchFiles)
  yield takeEvery(filesModule.FETCH_DETAILS, fetchDetails)
}