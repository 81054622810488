import styled from 'styled-components'

export const FormRow = styled.div`
  & + & {
    margin-top: 15px;
  }
`

export const FormWrapper = styled.section`
  max-width: 600px;
`

export const FormMessage = styled.h3`
  margin-top: 100px;
`
