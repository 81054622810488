import styled from 'styled-components'

export const Sidebar = styled.aside`
  width: 33%;
`

export const Detail = styled.section`
  width: 67%;
`

export const SidebarDetail = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`