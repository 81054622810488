import React, { Component, Fragment } from 'react'
import { Provider } from 'react-redux'
import store from './redux/store'
import { Home, About, Contact, SignUp, Login, Demo, FileSelection } from 'scenes'
import { GlobalStyles } from 'components'
import { Router, Location } from "@reach/router"

class App extends Component {
  render() {
    return (
        <Provider store={store}>
          <Fragment>
            <GlobalStyles />
            <Location>
              { ({ location }) => (
                  <Router location={location}>
                    <Home path="/" />
                    <About path="/about" />
                    <Contact path="/contact" />
                    <SignUp path="/sign-up" />
                    
                    <Demo path="demo">
                      <Login path="/" />
                      <FileSelection path=":userId" />
                    </Demo>
                  </Router>
              )}
            </Location>
          </Fragment>
        </Provider>
      
    )
  }
}

export default App
