import React from 'react'
import { Outer, Title } from './styles'

const Masthead = ({ title, children }) => (
    <Outer>
      <Title>{ title }</Title>
      { children }
    </Outer>
)

export default Masthead