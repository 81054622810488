import { createActions } from 'redux-actions'
import { combineReducers } from 'redux'

export const FETCH = 'files/FETCH'
export const FETCH_SUCCESS = 'files/FETCH_SUCCESS'

export const FETCH_DETAILS = 'files/FETCH_DETAILS'
export const FETCH_DETAILS_SUCCESS = 'files/FETCH_DETAILS_SUCCESS'

export const TOGGLE_LOADING = 'files/TOGGLE_LOADING'

export const SET_GROUP = 'files/SET_GROUP'

export const { files: filesActions } = createActions({
  [FETCH]: undefined,
  [FETCH_SUCCESS]: undefined,
  [FETCH_DETAILS]: (userId, groupName) => ({ userId, groupName }),
  [FETCH_DETAILS_SUCCESS]: undefined,
  [TOGGLE_LOADING]: isLoading => ({ isLoading }),
  [SET_GROUP]: selectedGroup => ({ selectedGroup })
})

const defaultCollection = {}

const collection = (state = defaultCollection, { type, payload }) => {
  switch(type) {
    case FETCH_SUCCESS:
      return { ...state, ...payload }
    
    default:
      return state
  }
}

const details = (state = {
  geoJson: []
}, { type, payload }) => {
  switch(type) {
    case FETCH_DETAILS_SUCCESS:
      return { ...state, ...payload }
    
    default:
      return state
  }
}

const defaultUtils = {
  isLoading: false,
  selectedGroup: ''
}

const utils = (state = defaultUtils, { type, payload }) => {
  switch(type) {
    case SET_GROUP:
    case TOGGLE_LOADING:
      return { ...state, ...payload }
    
    default:
      return state
  }
}


export const getCollection = state => state.files.collection
export const getDetails = state => state.files.details
export const getUtils = state => state.files.utils

export default combineReducers({
  collection,
  details,
  utils
})