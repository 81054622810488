import React, { Fragment } from 'react'
import { LayersControl } from 'react-leaflet'
import { Match } from "@reach/router"
import { Map } from 'components'
import FilesContainer from 'containers/FilesContainer'
import { Wrapper, FileSection, Content, File, ImageSelection, Description } from './styles'
import { ImageOverlay } from 'react-leaflet'

const getFileType = file => file.split('.').pop().toUpperCase()

const calcCenter = polygon => {
  const averages = polygon.reduce((acc, value) => {
    return [ (acc[0] + value[0] ), (acc[1] + value[1]) ]
  }, [0, 0])
  
  return [ averages[0] / polygon.length, averages[1] / polygon.length ]
}

const FileSelection = () => (
    <Match path="/demo/:userId">
      { props => (
          <FilesContainer userId={props.match.userId}>
            { ({ fileGroups, setGroup, selectedDetails, files }) => (
                <Fragment>
                  <Wrapper>
                    <FileSection>
                      <ImageSelection>
                        <h3>Select an image</h3>
                        { fileGroups.map( fileGroup => <File key={fileGroup} onClick={() => setGroup(fileGroup)}>{fileGroup}</File> ) }
                      </ImageSelection>
                      <Description>
                        <h3>Description</h3>
                        <p>{ selectedDetails.description ? selectedDetails.description : 'There is no description for this image.' }</p>
                      </Description>
                    </FileSection>
                    <Content>
                      {selectedDetails.geoJson.length !== 0 &&
                        <Map center={calcCenter(selectedDetails.geoJson)}>
                          { files.images.map( (image, index) => (
                              <LayersControl.Overlay name={`${getFileType(image)} Image`} checked={index === 0 }>
                                <ImageOverlay
                                    opacity={0.8}
                                    url={`http://63.32.216.156:8080/v1/${props.match.userId}/data/${image}`}
                                    bounds={selectedDetails.geoJson}
                                />
                              </LayersControl.Overlay>
                          )) }
                        </Map>
                      }
                    </Content>
                  </Wrapper>
                </Fragment>
            )}
          </FilesContainer>
      ) }
    </Match>
)

export default FileSelection