import styled from 'styled-components'
import { layout } from 'config'
import { colors } from 'config'

export const Outer = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid ${colors.text};
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 0 20px;
  height : ${layout.footerHeight}px;
`

export const FooterLink = styled.a``