import { Component } from 'react'
import { connect } from 'react-redux'
import { userActions } from 'modules/user'

class UserContainer extends Component {
  componentWillUnmount() {
    this.props.reset()
  }
  
  render() {
    const { children, ...restProps } = this.props
    return children(restProps)
  }
}

const mapStateToProps = state => ({
  message: state.user.utils.message,
  loginSuccess: state.user.utils.loginSuccess
})

const mapDispatchToProps = {
  fetchUser: userActions.fetch,
  login: userActions.login,
  reset: userActions.reset
}

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer)
