import React from 'react'
import {LayersControl, Map as LeafletMap, TileLayer} from 'react-leaflet'

const Map = ({center, zoom, children}) => (
    <LeafletMap center={center} zoom={zoom}>
      <LayersControl position="topright" collapsed={false}>
        <LayersControl.BaseLayer name="Streets">
          <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png"
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Features" checked>
          <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
        </LayersControl.BaseLayer>
        { children }
      </LayersControl>
      
    </LeafletMap>
)

Map.defaultProps = {
  center: [-33.9593572, 18.4792829],
  zoom: 15
}

export default Map