import axios from 'axios'
const baseUrl = 'http://api.dronesar.com:8080/v1'

export const createUser = async email => await axios(`${baseUrl}/user/${btoa(email)}`, {
  responseType: 'json',
  method: 'POST'
})

export const fetchUser = async email => await axios(`${baseUrl}/user/${btoa(email)}`, {
  method: 'GET'
})
