import styled from 'styled-components'
import { colors } from 'config'

const Label = styled.label`
  display: block;
  color: ${colors.text};
  font-weight: 600;
  margin-bottom: 10px;
`

export default Label