import React, { useState } from 'react'
import { Outer, Top, NavItem, Navigation, Logo } from './styles'
import {Menu} from 'styled-icons/boxicons-regular/Menu'
import logo from 'assets/images/logo_v2.svg'
import { Link } from "@reach/router"

export function NavigationMobile() {
  const [isOpen, setOpen] = useState(false)
  
  return (
      <Outer>
        <Top>
          <Link to="/">
            <Logo src={logo} alt="" />
          </Link>
          
          <Menu size={32} onClick={() => setOpen(!isOpen)} />
        </Top>
        <Navigation pose={isOpen ? "visible" : "hidden"}>
          <NavItem to="/">Home</NavItem>
          <NavItem to="/about">About us</NavItem>
          <NavItem to="/contact">Contact us</NavItem>
          <NavItem to="/sign-up">Sign up</NavItem>
          <NavItem to="/demo/">Demo</NavItem>
        </Navigation>
      </Outer>
  )
}

export default NavigationMobile