import styled from 'styled-components'
import { layout } from 'config'

const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: calc(100vh - ${layout.headerHeight}px - ${layout.footerHeight}px);
  margin: 0 auto;
  padding: 0 20px;
`

export default Content