import styled from 'styled-components'
import {breakpoints} from 'config'

export const Outer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 350px;
  background: #f2f2f2;
  padding: 12px;
  margin-bottom: 24px;
  
  @media (min-width: ${breakpoints.tabletUp}) {
    padding: 24px;
  }
  
  h5 {
    font-size: 16px;
    font-weight: lighter;
    font-style: italic;
    max-width: 600px;
    text-align: center;
    line-height: 1.5;
    margin: 30px 0 0 0;
  }
`

export const Title = styled.h1`
  font-family: 'Roboto Slab', serif;
  font-size: 40px;
  font-weight: lighter;
  margin: 0;
  text-align: center;
  
  @media (min-width: ${breakpoints.tabletUp}) {
    font-size: 80px;
  }
`