import React from 'react'
import { Page } from 'layouts'
import {  Section, SectionInner, Logo }  from './styles'
import { Masthead } from 'components'

import dronesar_logo from "assets/images/logo_v2.svg"
import tfp_graph from "assets/images/tfp_graph.png"
import radar_ag from 'assets/images/radar_ag.png'
import common_image from 'assets/images/common_image.png'
import plane_w_sar from 'assets/images/plane_w_sar.png'
import cloudsar_gif from 'assets/images/cloudSAR.gif'

const ImageStyling:any = {
  "min-width": '200px',
  "max-width": '450px',
  "padding": '0 15px',
  "display": "block",
  "margin-left": "auto",
  "margin-right": "auto",
  "width": "50%"
};

const Home = () => (
    <Page>
      <Masthead>
        <Logo src={dronesar_logo} alt="droneSAR logo" />
        <h5> droneSAR is a South African startup
          <br /> bringing advanced imaging and analytics to 21st century farming,
          <br /> using low cost aerial platforms and cloud processing. </h5>
      </Masthead>
      
      <Section>
        <SectionInner>
          <iframe title="Interactive demo video" width="95%" height="500px" src="https://www.youtube.com/embed/KbPtJ9TpfQ4" frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture, fullscreen"
                  allowFullScreen
          />
        </SectionInner>
      </Section>
      
      <Section>
        <SectionInner>
          <h2> The Challenge: The world needs 60% more food by 2050 </h2>

          <p> According to UN Food and Agricultural Agency projections, we will need <b> 60%  more food </b> in the next 30 years: </p>

            <p> <img src={tfp_graph} style={ImageStyling} alt="TFP Graph" /> </p>

            <p> Despite this, global growth in Total Factor Productivity (TFP) in the agricultural sector has slowed.
            In the developing world, things are even worse, with TFP growth only <b> half  of what it needs to be. </b>
            </p>
        </SectionInner>
      </Section>
  
  
      <Section>
        <SectionInner style={{"overflow": "auto", "padding": "12px", "background": "#e2e2e2"}}>
          <h2> The Solution: Low cost, widely-available Ag-insights </h2>
  
          <p> We believe the answer is Precision Agriculture: when farmers have better ways to
          monitor their crops, they maximise yield while minimising environmental impact.</p>

          <p> <img style={{...ImageStyling, ...{"float":"right"}}} src={radar_ag} alt="RADAR Ag Image" /> </p>

          <p>If a farmer had access to frequently updated metrics such as:</p>
          <ul>
            <li> Soil Moisture (as shown on the right) </li>
            <li> Leaf Area </li>
            <li> Tree Count </li>
            <li> Volume/Surface Ratio (QP) </li>
            <li> Crop lodging </li>
            <li> Crop Canopy Height </li>
          </ul>
          <p>Then we would close the TFP growth gap.</p>
        </SectionInner>
      </Section>

      <Section>
        <SectionInner>
          <h2> Consulting services droneSAR Consulting has to offer </h2>
  
          <p>
            Our vision at droneSAR is to assist research institutes and agricultural cooperatives in South Africa to
            unlock the potential of radar images, in combination with other sensors.
            <br/>
            To achieve this, we are working on key tools for precision agriculture:</p>
          <ul>
            <li> Deploying short range imaging radar sensor mounted to the Ikarus C42 microlight or bakkie, with a
            cloud-based image processing platform </li>
            <li> Assistance in acquiring satellite SAR image data and understanding them. </li>
            <li> Working with partners towards drone based solutions. </li>
          </ul>

            <SectionInner style={{"overflow": "auto", "padding": "12px", "background": "#e2e2e2"}}>
              <p> <img style={Object.assign({}, ImageStyling, {"float":"left"})} src={plane_w_sar} alt="Drone with SAR #1" /> </p>
              <h3> Our Sensor </h3>
              <p> We believe SAR imaging technology is superior to optical imaging in Agriculture, but is not fully
              understood.
              <br/>
              <br/>
              Unlike optical images, our sensor works at all times of day and night, and is not vulnerable to
              interference from inclement weather, foliage or even shade netting. </p>
            </SectionInner>

            <SectionInner style={{"overflow": "auto", "padding": "12px"}}>
              <p> <img style={Object.assign({}, ImageStyling, {"float":"right"})} src={common_image} alt="Insights Prototype" /> </p>
              <h3> Our Platform </h3>
              <p> Our cloud processing platform simplifies the process of turning data into insights.
              <br/>
              <br/>
              Farmers will be able to produce vital operational metrics such as soil moisture maps and leaf area
              indices at the press of a button.
              <br/>
              <br/>
              We can assist in setting up your own platforms and processing chains, or integrating with your existing
              processing chains. </p>
            </SectionInner>

            <SectionInner style={{"overflow": "auto", "padding": "12px", "background": "#e2e2e2"}}>
              <h3> Our Combined Offering</h3>
              <p> <img style={Object.assign({}, ImageStyling, {"width":"95%", "max-width":"1200px"})} src={cloudsar_gif} alt="CloudSAR Platform" /> </p>
              <p> Our years of experience with SAR and Cloud Processing will assist you to assess SAR image products,
              be it from our sensor or open SAR imaging from international suppliers. </p>
              <p>Interested? Feel free to <a href="./contact"> Contact us</a> or give our <a href="./demo"> demo </a> a try.</p>
            </SectionInner>

        </SectionInner>
      </Section>
    </Page>
)

export default Home