import styled from 'styled-components'
import { breakpoints } from 'config'

export const SectionTitle = styled.h2`
  margin-top: 90px;
  font-size: 48px;
  text-align: center;
  font-weight: lighter;
`

export const SectionDescription = styled.p`
  line-height: 1.5;
  margin-bottom: 100px;
`

export const TeamSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 30px;
  
  & > * + * {
    margin-left: 0;
    
    @media (min-width: ${breakpoints.largeMobileUp}) {
      margin-left: 24px;
    }
  }
  
  & > *:nth-child(2n + 3) {
    @media (min-width: ${breakpoints.largeMobileUp}) {
      margin-left: 0;
    }
    
    @media (min-width: ${breakpoints.tabletUp}) {
      margin-left: 24px;
    }
  }
  
  & > *:nth-child(3n + 4) {
    @media (min-width: ${breakpoints.tabletUp}) {
      margin-left: 0;
    }
  }
`