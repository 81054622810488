import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  
  .leaflet-container {
    min-height: 65vh;
  }
`

export const FileSection = styled.aside`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  order: 2;
  margin: 40px 0;
`

export const ImageSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 25%;
  border-right: 1px solid black;
  padding: 0 20px 0 0;
  
  h3 {
    margin-top: 0;
  }
`
export const Description = styled.div`
  padding: 0 20px;
  width: 75%;
  
  h3 {
    margin-top: 0;
  }
`

export const Content = styled.section`
  width: 100%;
`

export const File = styled.span`
  display: block;
  padding: 5px 0;
  cursor: pointer;
  text-decoration: ${ ({ active }) => active ? 'underline' : 'none' };
  
  &:hover {
    text-decoration: underline;
  }
`