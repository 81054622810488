import { createActions } from 'redux-actions'
import { combineReducers } from 'redux'

export const CREATE = 'user/CREATE'
export const CREATE_SUCCESS = 'user/CREATE_SUCCESS'
export const FETCH = 'user/FETCH'
export const FETCH_SUCCESS = 'user/FETCH_SUCCESS'
export const TOGGLE_LOADING = 'user/TOGGLE_LOADING'
export const SET_MESSAGE = 'user/SET_MESSAGE'
export const LOGIN = 'user/LOGIN'
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS'
export const RESET = 'user/RESET'

export const { user: userActions } = createActions({
  [CREATE]: undefined,
  [CREATE_SUCCESS]: undefined,
  [FETCH]: undefined,
  [FETCH_SUCCESS]: undefined,
  [TOGGLE_LOADING]: isLoading => ({ isLoading }),
  [SET_MESSAGE]: message => ({ message }),
  [LOGIN]: undefined,
  [LOGIN_SUCCESS]: undefined,
  [RESET]: undefined
})

const defaultUtils = {
  isLoading: false,
  message: '',
  signUpSuccess: false,
  loginSuccess: false
}

const utils = (state = defaultUtils, { type, payload }) => {
  switch(type) {
    case SET_MESSAGE:
    case TOGGLE_LOADING:
      return { ...state, ...payload }
      
    case LOGIN_SUCCESS:
      return { ...state,  loginSuccess: true}
      
    case CREATE_SUCCESS:
          return { ...state, signUpSuccess: true }
          
    case RESET:
          return defaultUtils
    
    default:
      return state
  }
}

export default combineReducers({
  utils
})