import React from 'react'
import { Input, Label, Button } from 'components'
import { Form, Formik, Field } from 'formik'
import { FormRow, FormWrapper, FormMessage } from './styles'
import UserContainer from 'containers/UserContainer'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  email: yup
      .string()
      .email('Please use a valid email address.')
      .required('Email is required')
})

const Login = () => (
    <UserContainer>
      { ({ loginSuccess, message, ...props }) => !loginSuccess ? (
        <FormWrapper>
          <Formik
            initialValues={{
              email: ''
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              props.login(values.email)
            }}
            render={() => (
              <Form>
                <h1>Login into demo</h1>
                <FormRow>
                  <Label>Email</Label>
                  <Field name="email"
                         placeholder="Your email"
                         component={Input} />
                </FormRow>
                <FormRow>
                  <Button type="submit">Submit</Button>
                </FormRow>
              </Form>
            )}/>
        </FormWrapper>
      ) : <FormMessage>{ message }</FormMessage> }
    </UserContainer>
)

export default Login
