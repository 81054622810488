import React from 'react'
import { ErrorMessage } from 'formik'
import { Outer, Input, Error } from './styles'

const InputComp = ({ field, ...props }) => (
    <Outer>
      <Input {...field} {...props} />
      <ErrorMessage name={field.name} render={ message => <Error>{ message }</Error>} />
    </Outer>
)

export default InputComp
