export const breakpoints = {
  mobileUp: '321px',
  largeMobileUp: '481px',
  tabletUp: '768px',
  desktopUp: '1025px',
  largeDesktopUp: '1261px',
}

export const colors = {
  text: '#18090b',
  primary: '#1687e1'
}

export const type = {
  montserrat: '"Montserrat", sans-serif',
  lora: '"Lora", serif'
}

export const layout = {
  footerHeight: 80,
  headerHeight: 80
}

export default {
  layout,
  type
}

