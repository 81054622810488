import { createSelector } from 'reselect'
import * as filesModule from 'modules/files'

export const getFileGroups = createSelector(
    [filesModule.getCollection],
    files => Object.keys(files)
)

export const getSelectedGroup = createSelector(
    [filesModule.getUtils],
    utils => utils.selectedGroup
)

export const getFiles = createSelector(
    [filesModule.getCollection, getSelectedGroup],
    (filesById, selectedGroup) => filesById[selectedGroup]
)