import { createGlobalStyle} from 'styled-components'
import { type, colors } from 'config'

const GlobalStyles = createGlobalStyle`
  *, *:after, *:before {
    box-sizing: border-box;
  }
  
  html {
    overflow: hidden;
    height: 100%;
  }

  body {
    padding: 0;
    margin: 0;
    background: #f2f2f2;
    font-family: ${type.montserrat};
    color: ${colors.text};
    height: 100%;
    overflow: auto;
  }
  
  h1, h2 {
    font-weight: normal;
    margin-bottom: 1.2em;
  }
  
  h3, h4 {
    font-weight: normal;
  }
  
  h2 {
    font-size: 32px;
  }
  
  h3 {
    font-size: 24px;
  }
  
  h4 {
    font-size: 18px;
  }
  
  a {
    color: ${colors.primary};
    text-decoration: none;
  }
`

export default GlobalStyles