import React from 'react'
import { colors } from 'config'
import { Outer, FooterLink } from './styles'
import { Twitter } from 'styled-icons/boxicons-logos/Twitter'
import { Github } from 'styled-icons/boxicons-logos/Github'

const Footer = props => (
    <Outer>
      <FooterLink href="https://twitter.com/droneSAR" target="_blank">
        <Twitter size={32} color={colors.text} />
      </FooterLink>
  
      <FooterLink href="https://github.com/dronesar" target="_blank">
        <Github size={32} color={colors.text} />
      </FooterLink>
    </Outer>
)

export default Footer