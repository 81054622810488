import React from 'react'
import { Page, Content } from 'layouts'
import { Input, Label, Button } from 'components'
import { Formik, Field, Form } from 'formik'
import { FormRow, FormWrapper, FormMessage } from './styles'
import { connect } from 'react-redux'
import { userActions } from 'modules/user'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  email: yup
          .string()
          .email('Please use a valid email address.')
          .required('Email is required')
})

const SignUpForm = props => (
    <FormWrapper>
      <Formik
          initialValues={{
            email: ''
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => props.onSubmit(values)}
          render={() => (
              <Form>
                <h1>Sign up to the demo</h1>
                <h4>Please provide email to gain access to the demo.</h4>
                <FormRow>
                  <Label>Email</Label>
                  <Field name="email"
                         placeholder="Your email"
                         component={Input} />
                </FormRow>
                <FormRow>
                  <Button type="submit">Submit</Button>
                </FormRow>
              </Form>
          )}/>
    </FormWrapper>
)

const SignUp = ({ createUser, signUpSuccess, message }) => (
    <Page>
      <Content>
        { signUpSuccess ?
            <FormMessage>{ message }</FormMessage>
            : <SignUpForm  onSubmit={createUser} />
        }
        
      </Content>
    </Page>
    
)

const mapStateToProps = state => ({
  user: state.user,
  signUpSuccess: state.user.utils.signUpSuccess,
  message: state.user.utils.message
})

const mapDispatchToProps = {
  createUser: userActions.create,
  reset: userActions.reset()
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)
