import { takeEvery, call, put } from 'redux-saga/effects'
import * as userModule from 'modules/user'
import {
  createUser as createUserApi,
  fetchUser as fetchUserApi
} from 'api/user'

function* createUser({ payload }) {
  try {
    yield call(createUserApi, payload.email)
    yield put(userModule.userActions.setMessage("Thank you for signing up for the droneSAR demo. An email with the sign-in link has been sent to the email address you provided."))
    
  } catch(error) {
    if(error.response.status === 406) {
      yield put(userModule.userActions.setMessage("Ah, you've already signed-up with us. Don't worry, we've sent a reminder with the login link to your email address"))
    }
    
    if(error.response.status === 500) {
      yield put(userModule.userActions.setMessage('Whoops! Something has gone wrong. We\'re very sorry - please try reloading the page'))
    }
  }
  
  finally {
    yield put(userModule.userActions.createSuccess())
  }
}

function* loginSaga({ payload }) {
  try {
    const { data } = yield call(fetchUserApi, payload)
    
    if(data.status === 'exists') {
      yield put(userModule.userActions.setMessage("Thank you for signing in to the droneSAR demo. An email with the sign-in link has been sent to the email address you provided."))
    }
  
    if(data.status === 'non-existent') {
      yield call(createUserApi, payload)
      yield put(userModule.userActions.setMessage("Ah, you've haven't signed up for our demo yet -  don't worry, we've setup an account for you, and sent an email with a login link to the email address you provided."))
    }
    
  } catch(err) {
    yield put(userModule.userActions.setMessage("Whoops! Something has gone wrong. We're very sorry - please try reloading the page"))
  }
  
  finally {
    yield put(userModule.userActions.loginSuccess())
  }
}

export default function* watchUserSaga() {
  yield takeEvery(userModule.CREATE, createUser)
  yield takeEvery(userModule.LOGIN, loginSaga)
}