import React from 'react'
import { Page, Content } from 'layouts'
import { TeamCard, Masthead } from 'components'

import {
  SectionTitle,
  SectionDescription,
  TeamSection
} from './style'

// Image imports
import minggs from 'assets/images/minggs.jpg'
import jinggs from 'assets/images/jinggs.jpg'
import ginggs from 'assets/images/ginggs.jpg'

const About = () => (
    <Page>
      <Masthead title="About us">
        <h5>droneSAR is a spinout from the renowned <a href="http://www.rrsg.uct.ac.za/">Radar and Remote Sensing Group </a>
          at the University of Cape Town, South Africa.</h5>
      </Masthead>
      
      <Content>
        <SectionTitle>The Team</SectionTitle>
        <SectionDescription>With over a 50 years of research and industrial engineering experience across the team, these are the people
          that will help farmers see their crops in a new way.</SectionDescription>

        <TeamSection>
          <TeamCard
              imageUrl={minggs}
              name="Mike Inggs"
              title="CTO"
              linkedinUrl="https://www.linkedin.com/in/michael-inggs-0b509a1/">
            <p> Mike is an Emeritus Professor of the University of Cape Town. Prior to joining UCT's department of
            Electrical Engineering in 1988, he worked in industry in the UK, USA and South Africa for over 15 years.
            <br /> Despite over 200 academic publications, he is most proud of the the over 120 Masters and PhD students
            he has supervised over the last 20 years.</p>
          </TeamCard>
          
          <TeamCard
              imageUrl={ginggs}
              name="Gordon Inggs"
              title="Software Lead"
              linkedinUrl="https://www.linkedin.com/in/gordoninggs/">
            <p>After completing his PhD in Computer Engineering at Imperial College London, Gordon spent 2 and a half
              years working at the Amazon Development Centre in Cape Town, working on the largest public cloud in the
              world, EC2. <br/> Currently focused on data science, he discusses the difference between the mean and the
              median almost everyday.</p>
          </TeamCard>

          <TeamCard
              imageUrl={jinggs}
              name="Jonathan Inggs"
              title="Chief Operations Officer"
              linkedinUrl="https://www.linkedin.com/in/jonathan-inggs-2781731b/">
            <p> Jonathan Inggs rounds out an all family affair. Jonathan has extensive experience in corporate brand building, 
	    marketing and logistics. <br/> Very good at making Engineers talk to mere mortals. </p>
          </TeamCard>

	</TeamSection>
      </Content>
    </Page>
)

export default About
