import styled from 'styled-components'

export const Logo = styled.img`
  max-width: 600px;
  width: 90%;
`

export const Section = styled.section`
  width: 100%;
  margin-bottom: 50px;
`

export const SectionInner = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`