import styled from 'styled-components'
import { Link } from "@reach/router"
import { layout, colors } from 'config'

export const Outer = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height : ${layout.headerHeight}px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 24px;
`

export const NavItem = styled(Link)`
  text-decoration: none;
  color: ${colors.text};
  font-weight: 600;
  padding-right: 30px;
  transition: 0.3s color;
  
  & + & {
    border-left: 1px solid ${colors.text};
    padding-left: 30px;
  }
  
  &:hover {
    color: ${colors.primary};
    transition: 0.3s color;
  }
`

export const SiteLinks = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const LoginLinks = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`