import React from 'react'
import { Page } from 'layouts'
import { Content } from 'layouts'
import {Masthead} from "components"

const Contact = props => (
    <Page>
      <Masthead title="Contact us" />
      <Content>
        <p>If you are interested in our services, or in investing, please contact us at <a href="mailto:info@dronesar.com">info@dronesar.com</a>.</p>
      </Content>
    </Page>
)

export default Contact