import styled from 'styled-components'
import { Link } from "@reach/router"
import posed from 'react-pose'
import { colors } from 'config'

const NavigationPose = posed.div({
  hidden: { top: '0', y: '-100%'},
  visible: { top: '100%', y: 0 }
})

export const Outer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
`

export const Top = styled.div`
  position: relative;
  z-index: 10;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
`

export const Logo = styled.img`
  width: 150px;
  height: 30px;
`

export const Navigation = styled(NavigationPose)`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 15px;
  background: #f2f2f2;
  width: 100%;
  box-shadow: 0 -5px 10px 5px rgba(0, 0, 0, 0.1);
`

export const NavItem = styled(Link)`
  text-decoration: none;
  color: ${colors.text};
  font-weight: bold;
  
  &[aria-current] {
    color: ${colors.primary};
  }
  
  & + & {
    margin-top: 15px;
  }
`