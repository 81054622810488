import axios from 'axios'
const baseUrl = 'http://api.dronesar.com:8080/v1'

export const fetchFiles = async userId => await axios(`${baseUrl}/${userId}/data`, {
  method: 'GET'
})

export const fetchDescription = async (userId, groupName) => await axios(`${baseUrl}/${userId}/data/${groupName}.txt`, {
  method: 'GET'
})

export const fetchGeoJson = async (userId, groupName) => await axios(`${baseUrl}/${userId}/data/${groupName}.geojson`, {
  method: 'GET'
})

