import { Component } from 'react'
import { connect } from 'react-redux'
import { filesActions, getDetails } from 'modules/files'
import { getFileGroups, getSelectedGroup, getFiles } from 'selectors/files'

class FilesContainer extends Component {
  componentDidMount() {
    this.props.fetchFiles(this.props.userId)
  }
  
  componentDidUpdate(prevProps) {
    if(this.props.selectedGroup !== prevProps.selectedGroup) {
      this.props.fetchDetails(this.props.userId, this.props.selectedGroup)
    }
  }
  
  render () {
    const { children, ...props } = this.props
    return children(props)
  }
}

const mapStateToProps = state => ({
  fileGroups: getFileGroups(state),
  selectedGroup: getSelectedGroup(state),
  files: getFiles(state),
  selectedDetails: getDetails(state)
})


const mapDispatchToProps = {
  fetchFiles: filesActions.fetch,
  setGroup: filesActions.setGroup,
  fetchDetails: filesActions.fetchDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesContainer)