import React from 'react'
import { Page, Content } from 'layouts'

const Demo = ({ children }) => (
    <Page>
      <Content>
        { children }
      </Content>
    </Page>
)

export default Demo