import React from 'react'
import { NavItem, SiteLinks, LoginLinks, Outer } from './styles'

const Navigation = props => (
    <Outer>
      <SiteLinks>
        <NavItem to="/">Home</NavItem>
        <NavItem to="/about">About us</NavItem>
        <NavItem to="/contact">Contact us</NavItem>
      </SiteLinks>
      <LoginLinks>
        <NavItem to="/sign-up">Sign up</NavItem>
        <NavItem to="/demo/">Demo</NavItem>
      </LoginLinks>
    </Outer>
)

export default Navigation